<template>
  <v-container style="padding: 0;">
    <!-- Botão para abrir o modal -->
    <v-btn
      :disabled="loading || !selectedBudgets.length"
      color="success"
      depressed
      size="small"
      @click="openModal"
    >
      <v-icon left>mdi-printer</v-icon> Rel. Fechamento Conta (Parcial)
    </v-btn>

    <!-- Modal para inserir o número da cobrança parcial -->
    <v-dialog v-model="showModal" max-width="500px">
      <v-card>
        <v-card-title class="headline">
          Informe o Nº da Cobrança Parcial
        </v-card-title>

        <v-card-text>
          <v-form ref="formRef">
            <v-text-field
              v-model="numeroCobrancaParcial"
              label="Nº da Cobrança Parcial"
              required
              type="number"
              outlined
              dense
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" text @click="cancelModal">Cancelar</v-btn>
          <v-btn color="green" text @click="confirmModal">Confirmar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>






<script>
import api from "../../../http";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsFunc from "../../../service/utilsFunc";
import UtilsPDF from '../../../service/utilsPDF';
import logo from "../../../assets/logo.png";
import gptw from "../../../assets/GPTW_2023-2024.png";
import ona from "../../../assets/ONA_Nivel_2.png";

const { withCRUDUtils } = UtilsFunc
export default {
  name: "ContaCobrancaParcial",
  components: {
  },
  data: () => withCRUDUtils({
    prorrogacao: [],
    budget: [],
    pacote: [],
    taxa: [],
    equipamentos: [],
    totalFee: null,
    totalEquipment: null,
    showModal: false,
    numeroCobrancaParcial: 5,
    loading: false,
  }),
  props: {
    selectedBudgets: {
      type: Array,
      default: () => []
    },
    username: String,
   
  },
  methods: {
    openModal() {
      // console.log('clicou')
      this.showModal = true; // Abrir o modal
      this.getPDF()
    },
    cancelModal() {
      this.showModal = false; // Fechar o modal sem salvar
    },
    confirmModal() {
      if (!this.numeroCobrancaParcial) {
        this.$toast.error("Por favor, preencha o Nº da Cobrança Parcial!");
        return;
      }
      // Fechar o modal e continuar com o processo
      this.showModal = false;
      this.$toast.success(`Cobrança Parcial Nº ${this.numeroCobrancaParcial} confirmada!`);
      this.getPDF()
      // Continue com a geração do PDF ou outras ações necessárias
    },
    async getPDF() {
      this.showModal = true;
      this.loading = true;
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      try {
        await Promise.all([
          this.getOrcamento(),
          this.getProcedimento(),
          this.getPacote(),
          this.getTaxa(),
          this.getEquipamentos()
        ]);
        await this.generatePDF()
        this.loading = false;
      } catch (error) {
        this.$toast.error("Erro ao carregar os dados (L-58):", error);
        this.loading = false;
      }
    },
    async download(dataurl, filename) {
      const httpsurl = !dataurl.includes("https") ? dataurl.replace("http", "https") : dataurl;
      const res = await fetch(httpsurl);
      const buffer = await res.blob();
      const url = URL.createObjectURL(buffer);
      const link = document.createElement("a");
      link.download = filename;
      link.href = url;
      link.click();
    },
    drawHeader(doc) {
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
      } catch (error) {
        this.$toast.warning('Falha ao adicionar a imagem ao PDF (L-78):', error);
      }

      const data = this.budget[0];

      const lineHeight = 6
      const xFixedPosition = 10;
      let initialYPosition = 45
      let yPosition = 45

      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      doc.setLineWidth(0.5); // largura da borda
      doc.roundedRect(5, 40, 200, 25, 3, 3, "S");
      // doc.roundedRect(x, y, largura, altura, border-radius, border-radius, 'preenchimento');

      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18); // Tamanho da fonte, ajuste conforme necessário
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text(`Fechamento da Conta Parcial Nº ${data?.id}`, 105, 23, { align: "center" });

      moment.locale("pt-br"); // garantir que seja horario de brasília independente do dispositivo

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");

      doc.setFontSize(8);
      const marginRight = 5; // Defina a margem desejada do lado direito (5px)
      const pageWidth = doc.internal.pageSize.getWidth(); // Obtenha a largura total da página
      const textWidth = doc.getTextWidth(`Gerado por: ${this.username}`); // Calcule a largura do texto "Gerado por: username"
      const xPosition = pageWidth - textWidth - marginRight; // Calcule a posição x para alinhar o texto à direita
      doc.text(`Gerado por: ${this.username}`, xPosition, 28);
      doc.text("Data e Hora da Impressão:", pageWidth - doc.getTextWidth("Data e Hora da Impressão:") - marginRight, 32);
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, pageWidth - doc.getTextWidth(`${UtilsPDF.formatDate(currentDate)} às ${hour}`) - marginRight, 36);

      let dataInicio = null;
      let dataFim = null;

      // Verifica qual número de cobrança parcial corresponde ao valor `numeroCobrancaParcial`
      if (this.numeroCobrancaParcial === data.n_cp_1) {
        dataInicio = data.data_inicio_cp_1;
        dataFim = data.data_fim_cp_1;
      } else if (this.numeroCobrancaParcial === data.n_cp_2) {
        dataInicio = data.data_inicio_cp_2;
        dataFim = data.data_fim_cp_2;
      } else if (this.numeroCobrancaParcial === data.n_cp_3) {
        dataInicio = data.data_inicio_cp_3;
        dataFim = data.data_fim_cp_3;
      } else if (this.numeroCobrancaParcial === data.n_cp_4) {
        dataInicio = data.data_inicio_cp_4;
        dataFim = data.data_fim_cp_4;
      }

      // Renderiza o período conforme as datas correspondentes ao número de cobrança parcial
      if (dataInicio && dataFim) {
        doc.setFontSize(11);
        doc.text(`Período de: ${UtilsPDF.formatDate(dataInicio)} até: ${UtilsPDF.formatDate(dataFim)}`, 71, 32);
      }

      doc.setFontSize(12);
      doc.text(`Paciente: ${data && data.paciente ? data.paciente : ''}`, xFixedPosition, initialYPosition);

      yPosition = initialYPosition + lineHeight
      const dataNascimento = data && data.data_nascimento ? `Data Nascimento: ${UtilsPDF.formatDate(data.data_nascimento)}` : 'Data Nascimento: ';
      doc.text(dataNascimento, 200, initialYPosition, { align: "right" });

      doc.text(`Operadora: ${data && data.convenio ? data.convenio : ''}`, xFixedPosition, yPosition);
      doc.text(`Nº da Carteira: ${data && data.nr_carteirinha ? data.nr_carteirinha : ''}`, 200, yPosition, { align: "right", });

      const paciente = this.procedimento[0]
      yPosition += lineHeight
      doc.text(`Modalidade: ${paciente && paciente.modalidade ? paciente.modalidade.nome : ''}`, xFixedPosition, yPosition);

      const maxWidth = 200;
      const spaceBetweenTexts = 5; // Espaço entre modalidadeText e CID
      
      const modalidadeText = `Modalidade: ${data && data.modalidade ? data.modalidade : ''}`;
      const modalidadeTextWidth = doc.getTextWidth(modalidadeText);
      
      if (paciente) {
        const cid = paciente.paciente.cid.nome || '';
        const cidDescricao = paciente.paciente.cid.descricao || '';
        const fullText = `CID: ${cid} - ${cidDescricao}`;

        const renderTextLine = (doc, text, x, y, align = 'left') => {
          doc.setFont("helvetica", "normal");
          doc.text(text, x, y, { align: align });
        };

        const remainingWidth = maxWidth - modalidadeTextWidth - spaceBetweenTexts;
        const splittedText = doc.splitTextToSize(fullText, remainingWidth);

        splittedText.forEach((line, i) => {
          const xPos = i === 0 ? 200 : 10;
          const align = i === 0 ? 'right' : 'left';
          const yPos = yPosition + (lineHeight * i);
          renderTextLine(doc, line, xPos, yPos, align);
        });

        yPosition += (lineHeight * splittedText.length);

      } else {
        doc.setFont("helvetica", "normal");
        doc.text(`CID: `, 200, yPosition, { align: "right" });
      }

      if (doc.internal.getNumberOfPages() === 1) {
        yPosition = 75
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text(`Segue o fechamento da conta conforme o utilizado pelo paciente durante o período acima informado.`, 12, yPosition);

        yPosition = 82
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text(`Ficamos a sua inteira disposição para quaisquer dúvidas que tenham.`, 12, yPosition);

        yPosition = 89
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text(`Obrigada.`, 12, yPosition);
      }
    },
    async generatePDF() {
      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);

      const pageWidth = 210;
      const maxHeight = 270;
      const lineHeight = 6;

      const data = this.budget[0];
      const prorrogacao = this.procedimento;
      let yPosition = 97;
      let xPosition = 10;

      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      const textWidth = doc.getTextWidth("Resumo da Conta 'Parcial'");
      const xCenteredPosition = pageWidth / 2 - textWidth / 2;
      doc.text("Resumo da Conta 'Parcial'", xCenteredPosition, yPosition);
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      yPosition += lineHeight * 2;

      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);

      const packageData = this.pacote.length > 0 ? this.pacote[0] : null;

      if (packageData) {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Pacote(s)", xPosition, yPosition);
        yPosition += lineHeight;
        let qtd = 0; // Use uma única variável para manter a quantidade selecionada
            if (packageData.n_cp_1 === this.numeroCobrancaParcial) {
              qtd = packageData?.qtd_cp_1;
            } else if (packageData.n_cp_2 === this.numeroCobrancaParcial) {
              qtd = packageData?.qtd_cp_2;
            } else if (packageData.n_cp_3 === this.numeroCobrancaParcial) {
              qtd = packageData?.qtd_cp_3;
            } else if (packageData.n_cp_4 === this.numeroCobrancaParcial) {
              qtd = packageData?.qtd_cp_4;
            }

        const nomePacote = (packageData?.pacote.nomecomercial && packageData?.pacote.nomecomercial.trim() !== "") ? `${packageData.pacote?.nome} - (${packageData?.pacote.nomecomercial})` : packageData.pacote?.nome;
        const row = {
          'Código': packageData.pacote?.codigo || '',
          'Pacote': nomePacote,
          'Qtd.': String( qtd || 0),
          'Valor Unitário': `${UtilsPDF.formatToBRL(packageData.valor_cobranca_pacote)}`,
          'Des %.': packageData.desconto_pacote || '--',
          'Acres %.': packageData.acrescimo_pacote || '--',
          'Valor Total': `${UtilsPDF.formatToBRL(packageData.valor_cobranca_pacote * qtd)}`
        };

        const columns = ['Código', 'Pacote', 'Qtd.', 'Valor Unitário', 'Des %.', 'Acres %.', 'Valor Total'];
        const rows = [Object.values(row)];

        doc.autoTable({
          startY: yPosition,
          head: [columns],
          body: rows,
          styles: { lineWidth: 0.1 },
          margin: { left: 8, right: 8 }
        });

        const tableHeight = doc.lastAutoTable.finalY;
        yPosition = tableHeight + lineHeight;
        yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);

        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`Valor Total do(s) Pacote(s): ${UtilsPDF.formatToBRL(packageData.valor_cobranca_pacote * qtd)}`, 200, yPosition, { align: "right" });
        yPosition += lineHeight * 2;
      } else {
        // console.log('Nenhum dado de pacote encontrado');
      }

      doc.setFontSize(14);
      doc.setFont("helvetica", "bold");
      doc.text("Procedimento(s)", xPosition, yPosition);
      yPosition += lineHeight;

      // Agrupa os procedimentos pelo procedimento_id e soma as quantidades
      const groupedProcedures = {};

      // Itera sobre cada item para realizar o agrupamento
      prorrogacao.forEach(item => {
        const procedimentoId = item.procedimento.procedimento_id;
        const qtdRealizada = item.quantidade;
        const valorUnitario = parseFloat(item.valor_cobranca) || 0;
        const valorTotal = valorUnitario * qtdRealizada;

        if (groupedProcedures[procedimentoId]) {
          // Se o procedimento já existe, soma as quantidades e atualiza o valor total
          groupedProcedures[procedimentoId]['Qtd Realizada'] += qtdRealizada;
          groupedProcedures[procedimentoId].valorTotal += valorTotal;
        } else {
          // Caso contrário, cria uma nova entrada para o procedimento
          const nomeProcedimento = (item?.procedimento.nomecomercial && item.procedimento.nomecomercial.trim() !== "")
            ? `${item.procedimento.procedimento} - (${item.procedimento.nomecomercial})`
            : item.procedimento.procedimento;

          groupedProcedures[procedimentoId] = {
            'Código': item.procedimento.codigo,
            'Procedimento': nomeProcedimento,
            'Frequência': item.procedimento.especial === true ? '' : item.frequencia.nome,
            'Qtd Realizada': qtdRealizada,
            'Valor Unitário': UtilsPDF.formatToBRL(valorUnitario),
            'Des %.': '--',
            'Acres %.': '--',
            'valorTotal': valorTotal // Mantém o valor total sem formatar inicialmente
          };
        }
      });

      // Converte o objeto agrupado em um array para a tabela
      const table = Object.values(groupedProcedures).map(item => {
        // Formata o valor total em BRL
        item['Valor Total'] = UtilsPDF.formatToBRL(item.valorTotal);
        delete item.valorTotal; // Remove o campo auxiliar valorTotal
        return item;
      });

      const columns = ['Código', 'Procedimento', 'Frequência', 'Qtd Realizada', 'Valor Unitário', 'Des %.', 'Acres %.', 'Valor Total'];
      const rows = [];

      table.forEach(item => {
        const row = [];
        columns.forEach(column => {
          row.push(item[column]);
        });
        rows.push(row);
      });



      doc.autoTable({
        startY: yPosition,
        head: [columns],
        body: rows,
        styles: { lineWidth: 0.1 },
        margin: { left: 8, right: 8 },
        columnStyles: {
          2: { cellWidth: 30 },
          7: { cellWidth: 28 }
        },
      });

      const tableHeight = doc.autoTable.previous.finalY; // Obtém a altura da tabela
      yPosition = tableHeight + lineHeight;

      // Cálculo do Valor Total do Orçamento
      const totalBudget = table.reduce((acc, item) => {
        const valorTotal = parseFloat(item['Valor Total'].replace('R$', '').replace('.', '').replace(',', '.')) || 0;
        // console.log(`Adicionando valor: ${valorTotal}`);
        return acc + valorTotal;
      }, 0);

      doc.setFontSize(10);
      doc.setFont("helvetica", "bold");
      doc.text(`Valor Total do(s) Procedimento(s): ${totalBudget ? UtilsPDF.formatToBRL(totalBudget) : 'R$ 0,00'}`, 200, yPosition, { align: "right" });
      yPosition += lineHeight * 2;

      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);
      const taxas = this.taxa;

      if (taxas && taxas.length > 0) {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Taxa(s)", xPosition, yPosition);

        yPosition += lineHeight;
        let totalFee = 0;

        const columns = [
          'Código',
          'Taxa',
          'Qtd.',
          'Valor Unitário',
          'Des %.',
          'Acres %.',
          'Valor Total'
        ];
        const rows = [];

        taxas.forEach(taxa => {
          if (taxa.ativo) {
            let qtd = 0; // Use uma única variável para manter a quantidade selecionada
            if (taxa.n_cp_1 === this.numeroCobrancaParcial) {
              qtd = taxa?.qtd_cp_1;
            } else if (taxa.n_cp_2 === this.numeroCobrancaParcial) {
              qtd = taxa?.qtd_cp_2;
            } else if (taxa.n_cp_3 === this.numeroCobrancaParcial) {
              qtd = taxa?.qtd_cp_3;
            } else if (taxa.n_cp_4 === this.numeroCobrancaParcial) {
              qtd = taxa?.qtd_cp_4;
            }
            const valorCobrancaTaxa = taxa?.valor_cobranca_taxa || 0;
            const valorCobrancaFinalTaxa = valorCobrancaTaxa * qtd;
            const nomeTaxa = (taxa?.taxa?.nomecomercial && taxa.taxa.nomecomercial.trim() !== "") ? `${taxa?.taxa?.taxa?.nome} - (${taxa?.taxa?.nomecomercial})` : taxa?.taxa?.taxa?.nome;


            const row = {
              'Código': taxa?.taxa?.codigo || '',
              'Taxa': nomeTaxa || '',
              'Qtd.': String(qtd || 0) ,
              'Valor Unitário': UtilsPDF.formatToBRL2(valorCobrancaTaxa),
              'Des %.': '--',
              'Acres %.': '--',
              'Valor Total': UtilsPDF.formatToBRL2(valorCobrancaFinalTaxa)
            };

            rows.push(Object.values(row));

            totalFee += valorCobrancaFinalTaxa;
          }
        });
        this.totalFee = totalFee
        try {
          doc.autoTable({
            startY: yPosition,
            head: [columns],
            body: rows,
            styles: { lineWidth: 0.1 },
            margin: { left: 8, right: 8 }
          });
        } catch (error) {
          this.$toast.error("Erro ao carregar os dados (L-371):", error);
          // console.error(error);
        }

        yPosition = doc.lastAutoTable.finalY + lineHeight;
        yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`Valor Total da(s) Taxa(s): ${UtilsPDF.formatToBRL2(this.totalFee)}`, 200, yPosition, { align: "right" });
        yPosition += lineHeight * 2;
      } else {
        // console.log('Nenhum dado de taxa encontrado');
      }

      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, 250, 80, this.drawHeader);

      const equipamentos = this.equipamentos;
      if (equipamentos && equipamentos.length > 0) {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Equipamento(s)", xPosition, yPosition);

        yPosition += lineHeight;
        let totalEquipment = 0;

        const columns = [
          'Código',
          'Equipamento',
          'Qtd.',
          'Valor Unitário',
          'Des %.',
          'Acres %.',
          'Valor Total'
        ];
        const rows = [];

        equipamentos.forEach(equipamento => {
          if (equipamento.ativo) {
            let qtd = 0; // Use uma única variável para manter a quantidade selecionada
            if (equipamento.n_cp_1 === this.numeroCobrancaParcial) {
              qtd = equipamento?.qtd_cp_1;
            } else if (equipamento.n_cp_2 === this.numeroCobrancaParcial) {
              qtd = equipamento?.qtd_cp_2;
            } else if (equipamento.n_cp_3 === this.numeroCobrancaParcial) {
              qtd = equipamento?.qtd_cp_3;
            } else if (equipamento.n_cp_4 === this.numeroCobrancaParcial) {
              qtd = equipamento?.qtd_cp_4;
            }
            const valorCobranca = equipamento?.valor_cobranca_equipamento || 0;
            const valorCobrancaFinal = valorCobranca * qtd;
            const nomeEquipamento = (equipamento?.equipamento?.nomecomercial && equipamento.equipamento.nomecomercial.trim() !== "") ? `${equipamento?.equipamento?.equipamento?.nome} - (${equipamento?.equipamento?.nomecomercial})` : equipamento?.equipamento?.equipamento?.nome;

            const row = {
              'Código': equipamento?.equipamento?.codigo || '',
              'Equipamento': nomeEquipamento || '',
              'Qtd.': String(qtd || ''),
              'Valor Unitário': UtilsPDF.formatToBRL2(valorCobranca),
              'Des %.':  '--',
              'Acres %.': '--',
              'Valor Total': UtilsPDF.formatToBRL2(valorCobrancaFinal)
            };

            rows.push(Object.values(row));

            totalEquipment += valorCobrancaFinal;
          }
        });
        this.totalEquipment = totalEquipment;
        try {
          doc.autoTable({
            startY: yPosition,
            head: [columns],
            body: rows,
            styles: { lineWidth: 0.1 },
            margin: { left: 8, right: 8 }
          });
        } catch (error) {
          this.$toast.error("Erro ao carregar os dados (L-438):", error);
        }

        yPosition = doc.lastAutoTable.finalY + lineHeight;
        yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`Valor Total do(s) Equipamento(s): ${UtilsPDF.formatToBRL2(this.totalEquipment)}`, 200, yPosition, { align: "right" });
        yPosition += lineHeight * 2;
      } else {
        // console.log('Nenhum dado de taxa encontrado');
      }

      const pacoteValor = this.pacote != '' ? this.pacote[0] : 0;

      const totalOrcamento =
        totalBudget + (
          pacoteValor?.valor_cobranca_final_pacote ?
            pacoteValor?.valor_cobranca_final_pacote :
            0
        ) + this.totalFee + this.totalEquipment

      const startDate = new Date(data?.data_inicio);
      const endDate = new Date(data?.data_fim);
      const numberOfDays = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1;
      const dailyAverage = totalOrcamento / numberOfDays;

      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, 260, 80, this.drawHeader);

      const text = 'da Conta'
      UtilsPDF.summary(doc, yPosition, lineHeight, dailyAverage, totalOrcamento, text)

      yPosition += lineHeight;
      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, 260, 80, this.drawHeader);

      const operadora = data && data.convenio ? data.convenio : '';
      // UtilsPDF.addSignatureBlock(doc, operadora, lineHeight, xPosition, 245);

      UtilsPDF.addPageNumbers(doc);
      const data_inicio = data && data?.data_inicio;
      const data_fim = data && data?.data_fim;
      const period = data_inicio && data_fim ? `${UtilsPDF.formatDateToFilename(data_inicio)}_ate_${UtilsPDF.formatDateToFilename(data_fim)}` : '';
      const nomePaciente = data && data.paciente ? data.paciente : '';

      const filename = `Resumo_da_Conta_${nomePaciente}_${operadora}_Período_${period}.pdf`;

      const pdfBuffer = doc.output('arraybuffer');
      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });
      const formData = new FormData();
      formData.append("file", pdfBlob, filename);
      formData.append("filename", filename);

      try {
        const { data: signedForm } = await api.post('multi_empresas/sign-pdf/', formData);
        const signedPdfUrl = signedForm.path;
        this.download(signedPdfUrl, filename);
        this.$toast.success('O download do relatório foi realizado com sucesso!');
      } catch (error) {
        console.error("Erro ao assinar o PDF:", error);
        this.$toast.error('Ocorreu um erro ao tentar gerar o PDF (L-496).', error.response ? error.response.data : error.message);
      }
    },
    async getProcedimento() {
      if (this.selectedBudgets.length > 0) {
        try {
          const n = this.numeroCobrancaParcial
          // console.log(n)
          const { data } = await api.get(`atendimentos/evolucoes/?sessao=${this.selectedBudgets[0]}`);
          // console.log(data)
          this.procedimento = data.filter( item => item.ativo && item.cobranca_parcial && item.n_cobranca_parcial === n );
          // console.log('Procedimento --> ', this.procedimento)
        } catch (error) {
          this.$toast.error('Erro ao buscar os dados da Prorrogação (L-505).', error);
        }
      }
    },
    async getOrcamento() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/sessoes/?orcamento=${this.selectedBudgets[0]}`);
          this.budget = data;
          // console.log('this.budget --> ',this.budget )
        } catch (error) {
          this.$toast.error('Erro ao buscar os dados do Orçamento(L-515).', error);
        }
      }
    },
    async getPacote() {
      if (this.selectedBudgets.length > 0) {
        try {
          const n = this.numeroCobrancaParcial
          // console.log('Pacote n -> ', n)
          const { data } = await api.get(`atendimentos/pacotes/?orcamento=${this.selectedBudgets[0]}`);
          this.pacote = data.filter(item => item.ativo && item.cobranca_parcial && (item.n_cp_1 === n || item.n_cp_2 === n || item.n_cp_3 === n || item.n_cp_4 === n));
          // console.log('Pacote --> ', this.pacote)
        } catch (error) {
          this.$toast.error('Erro ao buscar os dados do Pacote (L-526).', error);
        }
      }
    },
    async getTaxa() {
      if (this.selectedBudgets.length > 0) {
        try {
          const n = this.numeroCobrancaParcial
          // console.log('Taxa n -> ', n)
          const { data } = await api.get(`atendimentos/taxas/?orcamento=${this.selectedBudgets[0]}`);
          this.taxa = data.filter(item => item.ativo && item.cobranca_parcial && (item.n_cp_1 === n || item.n_cp_2 === n || item.n_cp_3 === n || item.n_cp_4 === n));
          // console.log('Taxas --> ', this.taxa)
        } catch (error) {
          this.$toast.error('Erro ao buscar os dados da Taxa (L-537).', error);
        }
      }
    },
    async getEquipamentos() {
      if (this.selectedBudgets.length > 0) {
        try {
          const n = this.numeroCobrancaParcial
          // console.log('Taxa n -> ', n)
          const { data } = await api.get(`atendimentos/equipamentos/?orcamento=${this.selectedBudgets[0]}`);
          this.equipamentos = data.filter(item => item.ativo && item.cobranca_parcial && (item.n_cp_1 === n || item.n_cp_2 === n || item.n_cp_3 === n || item.n_cp_4 === n));
          // console.log('Equipamentos --> ', this.equipamentos)
        } catch (error) {
          this.$toast.error('Erro ao buscar os dados do Equipamento (L-548).', error);
        }
      }
    },
  },
  // mounted() {
  //   this.openModal(); // Abre o modal quando a página é carregada
  // },
};
</script>
